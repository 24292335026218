import React from 'react'
import { Pane, Heading, Paragraph } from '@muhsis/ui'

import { SpotlightLight } from '../blocks'
import { Layout, SEO, BackgroundImageGrid } from '../components'
import { Header, Footer } from '../blocks'

const TermsPage = () => (
  <Layout>
    <SEO
      title="Kullanıcı Sözleşmesi"
      keywords={[
        `muhsis kullanıcı sözleşmesi`,
        `web tabanlı ön muhasebe`,
        `muhasebe`,
        `web muhasebe programı`,
        `online muhasebe programı`,
      ]}
    />
    <BackgroundImageGrid>
      <Header />
      <SpotlightLight title="Kullanıcı Sözleşmesi" />
      <Pane maxWidth={700} marginX="auto" marginTop="3rem" marginBottom="5rem">
        <Heading size={600} marginBottom="1rem">
          1- TARAFLAR :
        </Heading>
        <Paragraph marginBottom="1rem">
          Bir tarafta Kocasinan Cd. Uzay Sk. No:8 D:12 Küçükbakkalköy Ataşehir
          /İstanbul/Türkiye adresinde mukim bulunan (Tel : 0850 532 69 49 -
          Email : destek@muhsis.com )Muhsis Bilgi ve Teknoloji Hizmetleri A.Ş.
          (Bundan böyle “MUHSİS” olarak anılacaktır), diğer tarafta işbu
          sözleşmeyi internet ortamında (MUHSİS’e ait Sitede onaylayarak kabul
          eden) (Bundan böyle “ÜYE” olarak anılacaktır) , arasında aşağıda
          ayrıntıları yazılı koşullarda, karşılıklı tam bir mutabakat halinde,
          gayrikabili rücuen işbu Üyelik-Kullanıcı ve Gizlilik Sözleşmesi
          akdedilmiştir.
        </Paragraph>

        <Heading size={600} marginBottom="1rem">
          2- SÖZLEŞMENİN KONUSU :
        </Heading>
        <Paragraph marginBottom="1rem">
          İşbu sözleşme, MUHSİS’e ait bulunan www.muhsis.com alan adlı internet
          sitesinden ÜYE’nin gerçekleştirdiği kullanım üyelik işlemleri ile ;
          Muhsis programının internet sitesi üzerinden kullanılması ve bedelinin
          ödenmesi ile ilgili olarak, yasal çerçevede tarafların hak ve
          yükümlülükleri ile sair hususları düzenlemektedir.
        </Paragraph>

        <Heading size={600} marginBottom="1rem">
          3- SÖZLEŞMENİN KONUSU OLAN TİCARİ ÜRÜN :
        </Heading>
        <Paragraph marginBottom="1rem">
          ÜYE, www.muhsis.com web sitesinde özellikleri belirtilen ticari ürünü
          ( Online Ön Muhasebe ve Finans Programı) MUHSİS’den satın almayı ve
          böylece kullanım haklarına sahip olmayı talep etmekle ; MUHSİS de
          ÜYE’nin bu talebini kabul etmiş bulunmaktadır.
        </Paragraph>

        <Heading size={600} marginBottom="1rem">
          4- SÖZLEŞME KONUSU TİCARİ ÜRÜNÜN KULLANILMAYA BAŞLANMA ŞEKLİ VE
          BEDELİNİN ÖDENMESİ :
        </Heading>
        <Paragraph marginBottom="1rem">
          4.1. ÜYE öncelikle ; www.muhsis.com isimli internet sitesine girerek,
          üyelik formunu doldurmak ve işbu sözleşmeyi elektronik ortamda
          onaylamak (Kabul etmek) zorundadır.
        </Paragraph>

        <Paragraph marginBottom="1rem">
          4.2. ÜYE’nin talebinin akabinde kendisine MUHSİS tarafından bir
          aktivasyon maili gönderilecektir. ÜYE söz konusu bu aktivasyon maili
          ile gönderilen linke girerek üyelik işlemlerini tamamlamış sayılacak
          ve üyeliği başlayacaktır.
        </Paragraph>

        <Paragraph marginBottom="1rem">
          4.3. Aksi MUHSİS’e ait bulunan www.muhsis.com isimli internet
          sitesinde belirtilmediği sürece ; ÜYE satış bedelini kredi kartı ile
          peşin olarak ödemek zorundadır. MUHSİS, ÜYE’ye ilk 1 aylık ücretsiz
          kullanım hakkı tanıyabilir. Ücretsiz kullanım hakkı ve süresi ile sair
          kampanyalar www.muhsis.com isimli internet sitesinde ayrıca
          duyurulacaktır. Böyle bir bildirim yapılmamışsa ÜYE, MUHSİS’den
          ücretsiz kullanım hakkı ve üyelik talep edemez.
        </Paragraph>

        <Paragraph marginBottom="1rem">
          4.4. Ticari ürünün (Program ve üyelik) kullanım süreleri ve bu
          sürelere ilişkin bedeller ile satışlara ve kredi kartlarına ait
          kampanyalar (taksitli veya indirimli satışlar) internet sitesinde,
          “Paketler Sayfasında” ayrıca ve açıkça belirtilmiş bulunmaktadır. ÜYE,
          kampanyalarla ilgili ayrıntıları bilmediğini ileri süremez. ÜYE’nin
          alım işlemini gerçekleştirirken kullanmış olduğu kredi kartına ilişkin
          taksitlendirme, taksit sayısı , ödemenin ötelenmesi veya sunulan diğer
          avantajlar; MUHSİS’in ayrıca ve açıkça bir taahhüdü yoksa tamamen
          ilgili bankanın inisiyatifi altındadır. MUHSİS’in internet sitesinde
          açık bir taahhüdü olmadığı durumlarda ÜYE ; MUHSİS’e karşı anılan
          hususlarla ilgili olarak her hangi bir istek ve talepte bulunamaz.
          Ayrıca ÜYE ile kullanmış olduğu kredi kartını temin eden ilgili banka
          arasındaki ; taksitli satışlardaki faize, faiz oranına ve diğer her
          türlü yükümlülüklere ait özel şartlar MUHSİS’in sorumluluğunda
          değildir ve MUHSİS’i bağlamaz.
        </Paragraph>

        <Paragraph marginBottom="1rem">
          4.5. Aksi MUHSİS’e ait bulunan www.muhsis.com isimli internet
          sitesinde belirtildiği takdirde ; ÜYE satış bedelini havale veya EFT
          yöntemi ile ödeyebilir. ÜYE, havale veya EFT ile ödemeyi ; MUHSİS’e
          ait banka hesaplarına peşin olarak gerçekleştirecektir. Üye banka
          Hesap bilgilerini Muhsis ‘den talep etmelidir.
        </Paragraph>

        <Paragraph marginBottom="1rem">
          4.6. ÜYE, kapıdan ödemeyi MUHSİS’e teklif edemez. Ödemeler yalnızca
          kredi artı, havale veya EFT yoluyla gerçekleştirilebilir.
        </Paragraph>

        <Paragraph marginBottom="1rem">
          4.7. MUHSİS ; ödemelerle ilgili tüm faturaları, ÜYE tarafından
          iletilen iletişim adresine gönderecektir. Tüm faturalar, sonradan
          ödemeli üyelik halinde bir önceki üyelik dönemine ilişkin ücretleri,
          ön ödemeli üyeliklerde ise bir sonraki üyelik dönemine ilişkin
          ücretleri içerecektir. İlgili ücretlere ilişkin her türlü vergi ve
          harçların ödenmesinden münhasıran ÜYE sorumludur.
        </Paragraph>

        <Paragraph marginBottom="1rem">
          4.8. MUHSİS, ÜYE’ye ait kredi kartı ve ödeme bilgilerini
          saklamayacaktır. Anlaşmalı ödeme sistemleri üzerinden ödemeyi tahsil
          edecektir.
        </Paragraph>

        <Heading size={600} marginBottom="1rem">
          5- SÖZLEŞME KONUSU TİCARİ ÜRÜNÜN TESLİM ŞEKLİ :
        </Heading>
        <Paragraph marginBottom="1rem">
          ÜYE, sözleşme konusu ticari ürünün (Program kullanımı ve üyelik)
          kendisine fiziki olarak teslim edilmesini talep edemez. Zira bu ticari
          ürün, yalnızca www.muhsis.com isimli internet sitesinden
          kullanılabilir bir üründür ve fiziken teslimi mümkün değildir. ÜYE bu
          durumu bildiğini gayrikabili rücuen kabul ve taahhüt eder.
        </Paragraph>

        <Heading size={600} marginBottom="1rem">
          6- ÜYE TARAFINDAN CAYMA HAKKININ KULLANILMASI VE ŞEKLİ :
        </Heading>
        <Paragraph marginBottom="1rem">
          ÜYE, satın almış olduğu ticari ürünü kullanmaya başladıktan sonra,
          abone olduğu paket süresi bitene kadar cayma hakkını kullanamaz. ÜYE,
          bu hususu kabul ettiğini gayrikabili rücuen beyan eder.
        </Paragraph>

        <Heading size={600} marginBottom="1rem">
          7- ÜYENİN HAK VE YÜKÜMLÜLÜKLERİ :
        </Heading>
        <Paragraph marginBottom="1rem">
          7.1. ÜYE, MUHSİS’e ait bulunan www.muhsis.com isimli internet
          sitesinde ; ticari ürünlerle(Program kullanım ve üyelik) ilgili
          belirtilen teknik, kapasite ve diğer her türlü özelliği bildiğini,
          internet sitesinde açıklanan bilgilerin kendisi için yeterli olduğunu
          kabul eder. Ayrıca ÜYE, 18 yaşını doldurmuş olduğunu ve işbu
          sözleşmeyi akdetmek için gereken yasal ehliyete sahip bulunduğunu
          beyan eder.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          7.2. İşbu sözleşme ÜYE tarafından internet ortamında elektronik olarak
          onaylandığı anda yürürlüğe girer. Bu kapsamda ; ÜYE onaylama yaptıktan
          sonra işbu sözleşme şartlarının tümüyle hukuken bağlıdır.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          7.3. ÜYE ; üyelikten ve kullanım hakkından faydalanmak için MUHSİS
          tarafından talep edilen bilgileri tam, doğru ve güncel bir şekilde
          sağlayacağını kabul ve taahhüt eder. ÜYE’nin verdiği bilgiler de
          herhangi bir değişiklik olması halinde, söz konusu bilgiler ÜYE
          tarafından derhal güncellenecektir. Bu bilgilerin eksik veya gerçeğe
          aykırı olarak verilmesi ya da güncel olmaması nedeniyle internet
          sitesi veya programa erişim sağlanamamasından ve bunlardan
          faydalanılamamasından MUHSİS sorumlu değildir. Yine ÜYE’nin
          bilgilerinin yanlış veya eksik beyan edilmiş olmasından dolayı ;
          MUHSİS’in herhangi bir hukuki, vergisel veya cezai sorumluluğu
          bulunmamaktadır.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          7.4. ÜYE, tek bir kullanıcı hesabı açtırma hakkına sahip olup ;
          kullanıcı hesabının MUHSİS tarafından askıya alınması veya sona
          erdirilmesini takiben ÜYE tarafından aynı veya başka bilgiler
          kullanılarak ikinci bir hesap açılması yasaktır.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          7.5. ÜYE tarafından internet sitesine erişim e-posta adresi ve şifresi
          kullanılarak gerçekleştirilecektir. Bu şifrenin gizliliğinin ve
          güvenliğinin korunmasından münferiden ÜYE sorumludur. İnternet sitesi
          üzerinden söz konusu bilgilerin kullanımı ile gerçekleştirilen her
          türlü faaliyetin ÜYE tarafından gerçekleştirildiği kabul edilecek, bu
          faaliyetlerden doğan her türlü yasal ve cezai sorumluluk ÜYE’ye ait
          olacaktır. ÜYE, şifresinin yetkisiz kullanımı veya güvenliğin başka
          şekilde ihlalinden haberdar olduğunda bu durumu derhal MUHSİS’e
          bildirmek zorundadır.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          7.6. ÜYE, www.muhsis.com isimli internet sitesini yasalara uygun
          faaliyetleri için kullanacağını, işbu sözleşme ile yürürlükteki tüm
          yasal mevzuatlara uygun davranacağını kabul ve taahhüt eder.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          7.7. ÜYE tarafından paylaşılan ve girişi yapılan bilgilerle ilgili tüm
          sorumluluk ÜYE’ye aittir. ÜYE’nin girişini yaptığı bilgilerin ve
          içeriklerin yol açabileceği kayıp veya zararlardan MUHSİS’in herhangi
          bir hukuki ve cezai sorumluluğu bulunmamaktadır. Yine işbu maddede
          sayılanlarla sınırlı olmaksızın ; hukuka uygunluk, bilgi ve
          içeriklerin doğruluğu, faturaların ödenmesi, tahsilatın yapılması,
          finansal işlemler ve vergi bildirimine ilişkin olarak MUHSİS’in hiçbir
          hukuki ve cezai sorumluluğu bulunmamaktadır. Finansal işlemler, vergi
          ve diğer hususlar hakkında ilgili mevzuata uygunluğun sağlanması
          münhasıran ÜYE’nin sorumluluğundadır. Ayrıca MUHSİS ; finansal
          düzenlemeler başta olmak üzere, yürürlükteki mevzuattan kaynaklanan
          gereksinimlere dayanarak ÜYE’nin girmiş olduğu bilgi ve içerikleri,
          programdan ve sistemlerinden silme hakkına sahiptir. ÜYE ; izah edilen
          şekilde kaybolan veriler de dahil olmak üzere bu kapsamda meydana
          gelebilecek zararlardan MUHSİS’in herhangi bir hukuki ve cezai
          sorumluluğu bulunmadığını kabul, beyan ve taahhüt eder.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          7.8. ÜYE ; MUHSİS’in, MUHSİS’in üyeleri ile üçünü kişilerin,
          bilgisayar ve ağ sistemlerinin güvenlik ve bütünlüğünü tehlikeye
          düşürecek faaliyetlerde bulunmamayı, internet sitesinden
          faydalanılmasını engelleyecek hareketler yapmamayı, hatalı kullanım da
          bulunmamayı, internet sitesi ve programa yetkisiz erişim sağlamamayı,
          MUHSİS’in ve üçüncü kişilerin bilgisayar sistemlerine, cihazlarına ve
          yazılımlarına zarar verecek dosyalar veya hukuka aykırı içerikleri
          (ÜYE’nin üzerinde kullanma hakkı olmayan telif hakkıyla korunan veya
          ticari sır niteliğinde olan içerik, bilgi ve diğer materyaller de
          dahil olmak üzere) aktarmamayı ve yüklememeyi, kendisine sunulan
          programları değiştirmemeyi, kopyalamamayı, uyarlamamayı ve
          çoğaltmamayı, bu yasak hareketleri yapması halinde tüm hukuki ve cezai
          sorumluluğun kendisinde olduğunu ve MUHSİS’in ve üçüncü kişilerin tüm
          zararlarını tazmin edeceğini gayrikabili rücuen kabul, beyan ve
          taahhüt eder.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          7.9. ÜYE ; sadece tanıtım, reklam, kampanya, promosyon, duyuru vb.
          pazarlama faaliyetleri kapsamında kullanılması ile sınırlı olmak
          üzere, MUHSİS’in sahibi olduğu şirketin kendisine ait iletişim,
          portföy durumu ve demografik bilgilerini iştirakleri ya da bağlı
          bulunduğu grup şirketleri ile paylaşmasına muvafakat ettiğini kabul ve
          beyan eder. Bu kişisel bilgiler firma bünyesinde müşteri profili
          belirlemek, müşteri profiline uygun promosyon ve kampanyalar sunmak ve
          istatistiksel çalışmalar yapmak amacıyla kullanılabilecektir.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          7.10. İşbu sözleşme ile ilgili taraflara gönderilecek olan tüm
          bildirimler, ÜYE’nin bildirdiği e-posta adresi vasıtasıyla
          yapılacaktır.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          7.11. ÜYE, yüklediği içerikler ile internet sitesi ve programların
          kullanımından münhasıran sorumludur. ÜYE ; fikri mülkiyet ihlalleri,
          içerik, uygulama ve site’nin kullanımına ilişkin olarak üçüncü kişiler
          tarafından iletilebilecek her türlü iddia ve talepten (yargılama
          masrafları ve avukatlık ücretleri de dahil olmak üzere) MUHSİS’i
          sorumsuz kıldığını kabul ve taahhüt eder.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          7.12. ÜYE, işbu sözleşmeyi internet ortamında onayladıktan sonra
          kullanım ve üyelik bedelini işbu sözleşmede açıklanan usullerden
          birisiyle MUHSİS’e ödemeden ; üyelik ve program kullanım hakkı
          olduğunu MUHSİS’e karşı ileri süremez.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          7.13. ÜYE, ticari ürün ile ilgili olarak (Üyelik ve program kullanımı)
          ; garanti şartları ve yasal düzenlemeler çerçevesinde MUHSİS’den
          teknik destek talep edebilir. ÜYE’nin kullanmış olduğu sistemin üyelik
          ve kullanım için gerekli teknik yeterliliğe sahip olmaması durumu
          hariç olmak üzere; MUHSİS, ÜYE’ye kullanım ve üyelik için gerekli
          teknik desteği vereceğini taahhüt etmektedir.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          7.14. Kendi kusurundan kaynaklansın veya kaynaklanmasın ÜYE ; ticari
          ürün bedelinin kredi kartını kendisine veren banka tarafından MUHSİS’e
          ödenmemesinden dolayı sorumludur. Bu durumda MUHSİS, ÜYE’nin üyeliğini
          ve kullanım hakkını derhal askıya almak veya iptal etmek hak ve
          yetkisine sahiptir. Böyle bir iptal durumunda ÜYE, kullanım ve üyelik
          yaptığı süre içerisindeki bedeli MUHSİS’e ödemek zorundadır. Haksız ve
          bedelsiz kullanımdan dolayı MUHSİS’in tazminat hakları saklıdır.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          7.15. ÜYE ; her ne sebeple olursa olsun üyeliği ve kullanım hakkı sona
          erdiğinde ; ürüne ait şifreleri kullanmayacağını, programa ait teknik
          verileri ve şifreleri 3 Kişilerle paylaşmayacağını kabul ve taahhüt
          etmektedir. Aksi halde ÜYE, MUHSİS’in tüm ticari zararlarını tazmin
          edecektir.
        </Paragraph>

        <Heading size={600} marginBottom="1rem">
          8- MUHSİSİN HAK VE YÜKÜMLÜLÜKLERİ :
        </Heading>
        <Paragraph marginBottom="1rem">
          8.1. MUHSİS, ticari ürünü(Üyelik ve program kullanım hakkı)
          www.muhsis.com isimli internet sitesinde açıkladığı özelliklere sahip
          şekilde ; üyelik süresince ÜYE’nin kullanımına hazır bulundurmak
          zorundadır.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          8.2. MUHSİS ; işbu sözleşmenin bedeli ÜYE tarafından ödenmeden veya
          üyelik süresi bittikten sonra bedeli ödenerek üyelik yenilenmeden,
          ÜYE’ye hizmet vermek zorunda değildir.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          8.3. Hava muhalefeti, savaş hali, yangın, sel, deprem, terör eylemleri
          gibi nedenlerle MUHSİS tarafından ÜYE’ye hizmet verilememesi durumunda
          ; bu sebeplerle geçen süre, üyelik kullanma süresine eklenecektir. Bu
          tür durumlarda ÜYE, sözleşmenin feshini ve bedelin iadesini talep
          edemez.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          8.4. ÜYE, işbu sözleşmedeki yükümlülüklerinden her hangi birisine
          uymaz ise MUHSİS, sözleşmeyi tek taraflı ve tazminatsız olarak
          feshetme hakkına sahiptir.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          8.5. MUHSİS ; herhangi bir sebep göstermeksizin, tamamen kendi
          iradesine tabi olarak ÜYE’nin kullanıcı hesabı açma talebini reddetme
          hakkına sahiptir.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          8.6. İnternet sitesi ve programa yüklenen tüm bilgi ve içeriklerin
          kopyalarını saklama yükümlülüğü ÜYE de bulunduğundan ; MUHSİS söz
          konusu verilen kaybı ile ilgili olarak garanti vermemektedir.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          8.7. MUHSİS, ÜYE'ye bildirmeksizin ve ÜYE’nin yazılı veya sözlü
          onayını almaksızın, işbu internet sitesindeki veya programdaki
          içerikleri değiştirebilir, eklemelerde bulunabilir, modifikasyon
          yapabilir, kısaltabilir, ayrıştırabilir, çoğaltabilir, kopyalayabilir,
          saklayabilir, işleyebilir, formatını değiştirebilir, üzerinde
          oynayabilir, diğer içerikler ile birleştirebilir ve bunlarla kısıtlı
          olmamak üzere diğer benzeri tasarruflarda bulunabilir.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          8.8. MUHSİS’in herhangi bir çağrı merkezi işletme ve/veya benzer bir
          müşteri hizmeti verme zorunluluğu bulunmamaktadır. MUHSİS, çağrı
          merkezi işletme ve/veya benzer bir müşteri hizmeti ve/veya destek
          hizmeti verdiği durumda, bu şekilde vereceği hizmeti ÜYE'ye
          ücretlendirebilir.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          8.9. MUHSİS internet sitesindeki içerik üzerinden kazanacağı
          ücretlerden veya başka türlü elde edeceği doğrudan ve/veya dolaylı her
          türlü sair ticari kazançtan (İnternet sitesindeki reklamlar vs.)
          ÜYE'ye herhangi bir ücret ödemekle yükümlü değildir. Bu durumda ÜYE,
          MUHSİS’den herhangi bir nam altında bir bedel veya ücret talep
          etmeyeceğini peşinen ve gayrikabili rücu olmak üzere kabul, beyan ve
          taahhüt eder.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          8.10. MUHSİS, uygulanacak hukukun izin verdiği ölçüde, kar kaybı,
          şerefiye ve itibar kaybı, ikame ürün ve hizmet temini için yapılan
          harcama gibi kalemler de dahil ancak bunlarla sınırlı olmaksızın
          internet sitesinin kullanımı neticesinde meydana gelen hiçbir
          doğrudan, dolaylı, özel, arızi, cezai zarardan sorumlu olmayacaktır.
          Buna ek olarak şirket, zımni garanti, ticarete elverişlilik, belli bir
          amaca uygunluk da dahil ancak bunlarla sınırlı olmamak üzere, açık
          veya zımni hiç bir türlü garanti vermediğini de ayrıca beyan eder.
          MUHSİS’in işbu sözleşme kapsamındaki sorumluluğu her halükarda ilgili
          zararın doğduğu tarihe kadar ÜYE tarafından işbu sözleşmeye konu
          hizmetler kapsamında ödenmiş olan tutarla sınırlı olacaktır.
        </Paragraph>

        <Heading size={600} marginBottom="1rem">
          9- FİKRİ MÜLKİYET HAKLARI :
        </Heading>
        <Paragraph marginBottom="1rem">
          9.1. www.muhsis.com internet sitesi ve programlar üzerindeki her türlü
          hak, mülkiyet ve menfaat MUHSİS’e aittir. İşbu sözleşme ile ÜYE’ye
          internet sitesi ve içeriğindeki programları kullanmak üzere kişiye
          özel, dünya çapında, telifsiz, devredilemez ve münhasır olmayan lisans
          hakkı verilmektedir. Bu durum internet sitesi ile içeriğindeki
          programlara ilişkin hakların ve menfaatlerin ÜYE’ye devredildiği
          şeklinde yorumlanamaz. MUHSİS, hizmetlerin sağlanması amacıyla üçüncü
          kişilere alt lisans verme hakkına haizdir.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          9.2. ÜYE hiçbir şekilde internet sitesinden işleme eser oluşturma
          hakkına sahip değildir. İnternet sitesine ilişkin tarayıcı ve
          içeriklerin herhangi bir şekilde değiştirilmesi, MUHSİS’in açık ve
          yazılı izni olmaksızın internet sitesine veya internet sitesinden link
          verilmesi kesinlikle yasaktır.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          9.3. İnternet sitesinde yer alan ; unvan, işletme adı, marka, patent,
          logo, tasarım, bilgi ve yöntem gibi tescilli veya tescilsiz tüm fikri
          mülkiyet hakları MUHSİS’e ait olup, ulusal ve uluslararası hukukun
          koruması altındadır. İnternet sitesinin ziyaret edilmesi veya bu
          sitedeki hizmetlerden yararlanılması söz konusu fikri mülkiyet hakları
          konusunda hiçbir hak vermez.
        </Paragraph>

        <Heading size={600} marginBottom="1rem">
          10- GİZLİLİK :
        </Heading>
        <Paragraph marginBottom="1rem">
          10.1. MUHSİS, site üzerinden ÜYE ’lerin ilettiği kişisel bilgileri
          Üçüncü Kişilere açıklamayacaktır. Bu kişisel bilgiler ; kişi
          adı-soyadı, adresi, telefon numarası, cep telefonu, e- posta adresi
          gibi ÜYE’yi tanımlamaya yönelik her türlü diğer bilgiyi içermekte
          olup, kısaca ‘Gizli Bilgiler’ olarak anılacaktır.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          10.2. ÜYE ; sadece tanıtım, reklam, kampanya, promosyon, duyuru vb.
          pazarlama faaliyetleri kapsamında kullanılması ile sınırlı olmak
          üzere, MUHSİS’in kendisine ait iletişim, portföy durumu ve demografik
          bilgilerini iştirakleri ya da bağlı bulunduğu grup şirketleri ile
          paylaşmasına muvafakat ettiğini kabul ve beyan eder. Bunun dışında
          ÜYE’ye ve ÜYE tarafından internet sitesi üzerinden gerçekleştirilen
          işlemlere ait bilgiler, ÜYE ’nin güvenliği, MUHSİS’in yükümlülüklerini
          ifa ve bazı istatistiki değerlendirmeler için kullanılabilecektir.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          10.3. ÜYE ; girmiş olduğu içerik ve sair bilgilerin MUHSİS veya üçüncü
          kişiler tarafından Türkiye’de veya yurt dışında bulunan veri
          merkezlerinde saklanabileceğini kabul eder.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          10.4. Gizli Bilgiler, ancak resmi makamlarca usulü dairesinde bu
          bilgilerin talep edilmesi halinde ve yürürlükteki emredici mevzuat
          hükümleri gereğince resmi makamlara açıklama yapılmasının zorunlu
          olduğu durumlarda resmi makamlara açıklanabilecektir.
        </Paragraph>

        <Heading size={600} marginBottom="1rem">
          11- SÖZLEŞMENİN FESHİ :
        </Heading>
        <Paragraph marginBottom="1rem">
          11.1. MUHSİS, işbu sözleşmeyi aşağıdaki durumlarda bildirime gerek
          olmadan ve derhal geçerli olmak üzere, tazminatsız şekilde
          sonlandırabilir ve/veya ÜYE'nin üyeliğini iptal edebilir: (ı) Gerekçe
          göstermeksizin, dilediği zamanda veya (ıı) ÜYE'nin işbu sözleşmeye
          aykırı davranması veya (ııı) ÜYE'nin üyelik ücretini ödememesi veya
          (ıv) ÜYE’nin Kanun, Tüzük ve Yönetmeliklere aykırı davranması.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          11.2. ÜYE, MUHSİS’in işbu sözleşme şartlarını yerine getirmemesi
          halinde ; sözleşmeyi feshetme hakkına sahiptir. Ancak böyle bir
          durumda ÜYE ; üyelik bedellerini ödemekten kaçınamaz ve ödediği üyelik
          bedellerini MUHSİS’den geri talep edemez. ÜYE iptal ettiği üyeliğini,
          mevcut elektronik-postası ve şifresi ile giriş yaparak tekrar
          başlatabilir. Bu durumda, üyeliğini başlattığı andaki geçerli
          sözleşmedeki şartları kabul etmiş sayılır.
        </Paragraph>

        <Heading size={600} marginBottom="1rem">
          12- GENEL HÜKÜMLER :
        </Heading>
        <Paragraph marginBottom="1rem">
          12.1. İşbu sözleşmenin uygulanması sırasında tarafların sözleşmeden
          doğan her hangi bir hakkını kullanmamış olması ; bu hakkından açık,
          zımni, geçici veya sürekli olarak vazgeçtiği anlamına gelmez.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          12.2. Taraflar arasında çıkabilecek, işbu sözleşmeden kaynaklanan veya
          kaynaklanmayan tüm uyuşmazlıklarda ; MUHSİS’in defter, kayıt,
          belgeleri ile veri tabanı, e-mailler ve elektronik arşiv kayıtları
          kesin ve tek delil olarak nazara alınacağını taraflar kabul ve taahhüt
          etmişlerdir.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          12.3. ÜYE ile kayıt olurken bildirdiği e-mail vasıtasıyla veya
          internet sitesinde yer alan genel bilgilendirme ve duyurular
          aracılığıyla iletişim kurulacaktır. Tüm bu iletişim yolları, yazılı
          iletişimin yerini tutar.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          12.4. İşbu sözleşmede hüküm olmayan hallerde ; 4077, 6098 ve 6102
          Sayılı Yasa’lar ile ilgili Yönetmelik hükümleri uygulanır.
        </Paragraph>
        <Paragraph marginBottom="1rem">
          12.5. İşbu sözleşmeden kaynaklanan veya kaynaklanacak başta Damga
          Vergisi olmak üzere, her türlü vergi, harç ve resumu ödeme yükümlülüğü
          ÜYE’ye aittir. Şayet MUHSİS ödeme yapmışsa, ÜYE’ye rücu hakkı
          bulunmaktadır.
        </Paragraph>

        <Heading size={600} marginBottom="1rem">
          13- İHTİLAFLARIN ÇÖZÜMÜ VE YETKİLİ YARGI ORGANLARI :
        </Heading>
        <Paragraph marginBottom="1rem">
          İşbu sözleşmeden doğabilecek tüm anlaşmazlıkların çözümünde ; İstanbul
          Anadolu Mahkeme ve İcra Daireleri yetkilidir. Ayrıca ihtilaflar,
          Türkiye Cumhuriyeti Devleti yasal düzenlemeleri çerçevesinde çözüme
          kavuşturulacaktır. İşbu sözleşme ; toplam 13(Onüç) ana madde ve ana
          maddelere ait alt maddeler içermektedir. ÜYE, sözleşmenin tamamını
          okuduğunu, içeriğindeki tüm maddeleri kayıtsız şartsız kabul ettiğini
          ve onayladığını gayrikabili rücuen beyan, kabul ve taahhüt eder.
        </Paragraph>
      </Pane>
    </BackgroundImageGrid>
    <Footer />
  </Layout>
)
export default TermsPage
